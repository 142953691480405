import { gql } from '@apollo/client';

export default gql`
  fragment executiveFragment on Executive {
    code
    email
    name
    business
  }
`;
