import { gql } from '@apollo/client';

export default gql`
  fragment minimalLeadFragment on LeadOutPutFragment {
    _id
    planId
    companyRut
    companyName
    companyRut
    position
    isUpselling
    name
    lastname
    token
    email
    phone
    business
    workers
    transactionDate
    totalCompany {
      uf
    }
    variants {
      variantId
      workers
      totalVariant {
        uf
      }
    }
    createdAt
    admin {
      name
      lastname
      position
      email
      phone
      rut
    }
    stepsHiring {
      step
      status
    }
    entryDate
    status
  }
`;
