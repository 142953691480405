import { gql } from '@apollo/client';

export default gql`
  fragment brokerFragment on Broker {
    _id
    name
    rut
    code
    commission
    primary
    character
    callCenter
    promoBanner
    logo
  }
`;
