import { gql } from '@apollo/client';

import minimalLeadFragment from '../fragments/minimalLeadFragment';

export default gql`
  query getMailingLead($id: String!) {
    getMailingLead(id: $id) {
      ...minimalLeadFragment
    }
  }
  ${minimalLeadFragment}
`;
