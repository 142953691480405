import { PayloadVariant } from '@/src/types/variants';
import { State } from './reducers';

import {
  wizardPersonalInfoStep,
  wizardEmailStep,
  wizardWorkersStep,
  wizardPlansStep,
  wizardCoveragesStep,
  wizardVariantsStep,
  wizardSummaryStep,
  wizardFormStep,
} from '../../utils/constants';

import {
  // Workers
  SET_WORKERS,
  RESET_WORKERS,

  // Email
  SET_EMAIL_VALIDATION,
  RESET_EMAIL_VALIDATION,

  // Pre Plan
  SET_PRE_PLAN_SELECTED,
  SET_PRE_PLAN_TYPE,
  RESET_PRE_PLAN_SELECTED,

  // Plans
  SET_PLAN_SELECTED,
  RESET_PLAN_SELECTED,

  // Coverages
  SET_DENTAL_COVERAGE,
  SET_CATASTROPHIC_COVERAGE,
  RESET_SELECTED_COVERAGES,

  // Variants
  SET_VARIANT_SELECTED,
  RESET_VARIANT_SELECTED,

  // Step
  SET_CURRENT_STEP,
  RESET_CURRENT_STEP,

  // Form
  SET_FORM_VALIDATION,
  RESET_FORM_VALIDATION,
  SET_USER_DATA,
  RESET_USER_DATA,
  SET_SEND_LEAD,
  RESET_SEND_LEAD,

  // Wizard
  RESET_WIZARD,
  RECOVER_FROM_STORAGE,
  RESET_EMAIL,
  RESET_COMPANY_DATA,
  SET_LOADING_WIZARD,
  SET_BUSINESS,
  RESET_BUSINESS,

  // Personal Info, 1st Step
  SET_PERSONAL_INFO_VALIDATION,
  RESET_PERSONAL_INFO_VALIDATION,

  // Upselling Plan
  SET_IS_UPSELLING_PLAN,

  // Branding
  SET_BRANDING,
  RESET_BRANDING,
  SET_FORM_DATA,

  // Executive
  SET_EXECUTIVE,
  RESET_EXECUTIVE,
  SET_TOASTER_WIZARD,
} from './actionTypes';

// Workers
export const setWorkers = (payload: number) => ({
  type: SET_WORKERS,
  payload,
});

export const resetWorkers = () => ({
  type: RESET_WORKERS,
});

// Pre Plan
export const setPrePlanSelected = (payload: any) => ({
  type: SET_PRE_PLAN_SELECTED,
  payload,
});

export const setPrePlanType = (payload: any) => ({
  type: SET_PRE_PLAN_TYPE,
  payload,
});

export const resetPrePlanSelected = () => ({
  type: RESET_PRE_PLAN_SELECTED,
});

// Plans
export const setPlanSelected = (payload: any) => ({
  type: SET_PLAN_SELECTED,
  payload,
});

export const resetPlanSelected = () => ({
  type: RESET_PLAN_SELECTED,
});

// Coverages
export const setDentalCoverage = (payload: any) => ({
  type: SET_DENTAL_COVERAGE,
  payload,
});

export const setCatastrophicCoverage = (payload: any) => ({
  type: SET_CATASTROPHIC_COVERAGE,
  payload,
});

export const resetSelectedCoverages = () => ({
  type: RESET_SELECTED_COVERAGES,
});

// Variants

export const setVariantSelected = (payload: PayloadVariant) => {
  return { type: SET_VARIANT_SELECTED, payload };
};

export const resetVariantSelected = () => ({
  type: RESET_VARIANT_SELECTED,
});

// Step
export const setCurrentStep = (payload: any) => async (dispatch: any, getState: any) => {
  const { currentStep } = getState().wizard;

  await dispatch({
    type: SET_CURRENT_STEP,
    payload,
  });

  const newStep = getState().wizard.currentStep;

  if (
    (currentStep === wizardPersonalInfoStep && newStep === wizardEmailStep) ||
    (currentStep === wizardEmailStep && newStep === wizardWorkersStep) ||
    (currentStep === wizardWorkersStep && newStep === wizardPlansStep) ||
    (currentStep === wizardPlansStep && newStep === wizardCoveragesStep) ||
    (currentStep === wizardCoveragesStep && newStep === wizardVariantsStep) ||
    (currentStep === wizardVariantsStep && newStep === wizardSummaryStep) ||
    (currentStep === wizardSummaryStep && newStep === wizardFormStep) ||
    (currentStep === wizardVariantsStep && newStep === wizardFormStep) ||
    (currentStep === wizardFormStep && newStep === wizardSummaryStep)
  ) {
    window.scrollTo(0, 0);
  }
};

export const resetCurrentStep = () => ({
  type: RESET_CURRENT_STEP,
});

// Form
export const setFormValidation = (payload: any) => ({
  type: SET_FORM_VALIDATION,
  payload,
});

export const resetFormValidation = () => ({
  type: RESET_FORM_VALIDATION,
});

export const setUserData = (payload: any) => ({
  type: SET_USER_DATA,
  payload,
});

export const resetUserData = () => ({
  type: RESET_USER_DATA,
});

export const setSendLead = (payload: any) => ({
  type: SET_SEND_LEAD,
  payload,
});

export const resetSendLead = () => ({
  type: RESET_SEND_LEAD,
});

export const resetWizard = () => ({
  type: RESET_WIZARD,
});

export const recoverFromStorage = (payload: any) => ({
  type: RECOVER_FROM_STORAGE,
  payload,
});

export const setEmailValidation = (payload: any) => ({
  type: SET_EMAIL_VALIDATION,
  payload,
});

export const resetEmailValidation = () => ({
  type: RESET_EMAIL_VALIDATION,
});

export const setPersonalInfoValidation = (payload: any) => ({
  type: SET_PERSONAL_INFO_VALIDATION,
  payload,
});

export const resetPersonalInfoValidation = () => ({
  type: RESET_PERSONAL_INFO_VALIDATION,
});

export const resetEmail = (payload: any) => ({
  type: RESET_EMAIL,
  payload,
});

export const resetCompanyData = (payload: any) => ({
  type: RESET_COMPANY_DATA,
  payload,
});

export const setLoading = (payload: any) => ({
  type: SET_LOADING_WIZARD,
  payload,
});

export const setToaster = (payload: string | null) => ({
  type: SET_TOASTER_WIZARD,
  payload,
});

export const setBusiness = (payload: any) => ({
  type: SET_BUSINESS,
  payload,
});

export const resetBusiness = () => ({
  type: RESET_BUSINESS,
});

export const setIsUpsellingPlan = (payload: any) => ({
  type: SET_IS_UPSELLING_PLAN,
  payload,
});

export const setBranding = (payload: any) => ({
  type: SET_BRANDING,
  payload,
});

export const resetBranding = () => ({
  type: RESET_BRANDING,
});

export const setExecutive = (payload: State['executive']) => ({
  type: SET_EXECUTIVE,
  payload,
});

export const resetExecutive = () => ({
  type: RESET_EXECUTIVE,
});

export const setExtraFormData = (payload: State['extraFormData']) => ({
  type: SET_FORM_DATA,
  payload,
});
