import { userHash } from '@/utils/commonFunctions';
import { AvailablePlan as AvailablePlanType, Variant } from '../../types/availablePlans';
import { StoreType } from '../store/index';
import { formatRutWithoutDots } from '@/utils/format';

// Workers
export const getWorkersSelected = ({ wizard }: StoreType) => wizard.quantity;

// Pre Plans
export const getPrePlanSelected = ({ wizard }: StoreType) => wizard.prePlanSelected;

export const getPrePlanSelectedRestored = ({ wizard, plans }: StoreType) => {
  const { prePlanSelected, planSelected } = wizard;

  if (!!prePlanSelected) {
    return prePlanSelected;
  }

  const selectedPlan = plans.availablePlans.find(
    ({ _id }: AvailablePlanType) => _id === planSelected
  );
  const filteredPlansByType = plans.availablePlans.filter(
    ({ type }: AvailablePlanType) => type === selectedPlan?.type
  );

  const basePlan = filteredPlansByType.find(
    (plan: AvailablePlanType) =>
      plan.coverage.dental === null && plan.coverage.catastrophic === null
  );

  return basePlan?._id;
};

export const getSelectedPrePlanType = ({ wizard }: StoreType) => {
  const { prePlanType } = wizard;

  return prePlanType;
};

// Plans
export const getPlanSelected = ({ wizard }: StoreType) => wizard.planSelected;

// Coverages
export const getSelectedCoverages = ({ wizard }: StoreType) => wizard.coveragesSelection;

// Variants
export const getVariantSelected = ({ wizard }: StoreType) => wizard.variantSelected;

export const getVariantUfSelected = ({ wizard, plans }: StoreType) => {
  const plan = plans.availablePlans.find(
    (plan: AvailablePlanType) => plan._id === wizard.planSelected
  );

  const ufs = plan.variants.map((variant: any) => {
    return variant.price.uf;
  });

  return ufs;
};

export const getTotalCompany = ({ wizard, plans }: StoreType) => {
  const workers = wizard.quantity;
  const { availablePlans } = plans;

  const planSelected = availablePlans.find(
    ({ _id }: AvailablePlanType) => _id === wizard.planSelected
  );

  if (!planSelected) {
    return 0;
  }

  const planPrice = (planSelected?.price?.uf || 0) * Number(workers);

  const variantsSum =
    wizard.variantSelected?.selected?.reduce((acum: number, variant: any) => {
      return acum + (variant?.totalVariant?.uf as number);
    }, 0) || 0;

  return variantsSum + planPrice;
};

export const getTotalCompanySigned = ({ wizard, panel, plans }: StoreType) => {
  const workers = panel.formsSigned.length;
  const { availablePlans } = plans;

  const planSelected = availablePlans.find(
    ({ _id }: AvailablePlanType) => _id === wizard.planSelected
  );

  if (!planSelected) {
    return 0;
  }
  const planPrice = (planSelected?.price?.uf || 0) * Number(workers);

  const initial = 0;
  let minimum: number = initial;
  let one: number = initial;
  let morethanone: number = initial;

  panel.formsSigned.forEach((formSigned: any) => {
    if (formSigned.familyGroup.length === 0) minimum += 1;
    if (formSigned.familyGroup.length === 1) one += 1;
    if (formSigned.familyGroup.length >= 2) morethanone += 1;
  });

  const countSignedVariants = [minimum, one, morethanone];

  const variantsSum =
    planSelected.variants.reduce((acum: number, variant: Variant, index: number) => {
      return acum + variant.price.uf * countSignedVariants[index];
    }, 0) || 0;

  return variantsSum + planPrice;
};

export const getTotalCompanyById =
  (planId: string) =>
  ({ wizard, plans }: StoreType) => {
    const workers = wizard.quantity;
    const { availablePlans } = plans;

    const planSelected = availablePlans.find(({ _id }: AvailablePlanType) => _id === planId);

    if (!planSelected) {
      return 0;
    }

    const planPrice = (planSelected?.price?.uf || 0) * Number(workers);

    const variantsSum =
      wizard.variantSelected?.selected?.reduce((acum: number, variant: any) => {
        return acum + (variant?.totalVariant?.uf as number);
      }, 0) || 0;

    return variantsSum + planPrice;
  };

export const getTotalByPlan = ({ wizard, plans }: StoreType) => {
  const workers = wizard.quantity;
  const { availablePlans } = plans;

  const planSelected = availablePlans.find(
    ({ _id }: AvailablePlanType) => _id === wizard.prePlanSelected
  );

  if (!planSelected) {
    return 0;
  }

  const planPrice = planSelected?.price?.uf || 0;

  return planPrice * Number(workers);
};

export const getTotalByPlanSigned = ({ panel, wizard, plans }: StoreType) => {
  const workers = panel.formsSigned.length;
  const { availablePlans } = plans;

  const planSelected = availablePlans.find(
    ({ _id }: AvailablePlanType) => _id === wizard.prePlanSelected
  );

  if (!planSelected) {
    return 0;
  }

  const planPrice = planSelected?.price?.uf || 0;

  return planPrice * Number(workers);
};

export const getTotalVariants = ({ wizard }: StoreType) => {
  const variantsSum =
    wizard.variantSelected?.selected?.reduce((acum: number, variant: any) => {
      return acum + (variant?.totalVariant?.uf as number);
    }, 0) || 0;

  return variantsSum;
};

export const getFinalVariants = ({ wizard }: StoreType) =>
  wizard.variantSelected?.selected?.reduce(
    (final: any, { variantId, workers, totalVariant }: any) => {
      return [
        ...final,
        {
          variantId,
          workers,
          totalVariant,
        },
      ];
    },
    []
  ) || [];

// Step
export const getCurrentStep = ({ wizard }: StoreType) => wizard.currentStep;

export const getFormValidationStep = ({ wizard }: StoreType) => wizard.formValidation;

export const getPersonalInfoValidation = ({ wizard }: StoreType) => wizard.personalInfoValidation;

export const getEmailValidation = ({ wizard }: StoreType) => wizard.emailValidation;

export const getUserData = ({ wizard }: StoreType) => wizard.userData || {};

export const getSendLeadOrder = ({ wizard }: StoreType) => wizard.sendLead;

export const getLoading = ({ wizard }: StoreType) => wizard.loading;

export const getToaster = ({ wizard }: StoreType) => wizard.toaster;

export const getBusiness = ({ wizard }: StoreType) => wizard.business;

export const getIsUpselling = ({ wizard }: StoreType) => wizard.isUpselling;

export const getBranding = ({ wizard }: StoreType) => wizard.branding;

export const getExecutive = ({ wizard }: StoreType) => wizard.executive;

export const getExtraFormData = ({ wizard }: StoreType) => wizard.extraFormData;

export const getDataLayerInfo = ({ wizard, plans }: StoreType) => {
  const { quantity, prePlanType, coveragesSelection, userData, variantSelected } = wizard;

  const hasVariant = (arr: any[]): boolean =>
    arr.some((variant: any) => variant.totalVariant.uf !== 0);

  return {
    event_params: {
      numero_trabajadores: String(quantity || ''),
      product_type: prePlanType,
      cobertura_dental: coveragesSelection.dental ? 'si' : 'no',
      cobertura_catastrofica: coveragesSelection.catastrophic ? 'si' : 'no',
      proteger_carga: hasVariant(variantSelected.selected) ? 'si' : 'no',
      valor: getTotalCompany({ wizard, plans } as StoreType).toFixed(2),
    },
    user: {
      userId: !!userData.companyRut ? userHash(formatRutWithoutDots(userData.companyRut)) : '',
      useremail: userData.email,
    },
  };
};
