function fadeInUpElement(
  sectionElement: HTMLElement | null,
  element: HTMLElement | null,
  offPercentage: number
) {
  const windowHeight = window.innerHeight;
  const sectionTop: number = sectionElement?.getBoundingClientRect().y || 0;
  const offWindowHeight = windowHeight * offPercentage;
  const sectionY = windowHeight - sectionTop;
  const opacity = (offWindowHeight + sectionY) / windowHeight;

  if (!!element && sectionTop >= offWindowHeight && sectionY >= offWindowHeight) {
    element.style.setProperty('--opacity', String(opacity));
    element.style.setProperty('--position-y', `${sectionTop - offWindowHeight}px`);
  } else if (!!element && sectionTop < offWindowHeight) {
    element.style.setProperty('--opacity', '1');
    element.style.setProperty('--position-y', '0px');
  }
}

function fadeInUpAnimation(
  sectionElement: HTMLElement | null,
  elements: HTMLCollectionOf<Element> | HTMLElement,
  _offPercentage = 0.4 // The section porcentage off in which the animation will start
) {
  let offPercentage = _offPercentage;

  if (elements instanceof HTMLCollection) {
    const count = elements.length;

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;

      fadeInUpElement(sectionElement, element, offPercentage);

      offPercentage -= offPercentage / count;
    }
  } else {
    const element = elements as HTMLElement;

    fadeInUpElement(sectionElement, element, offPercentage);
  }
}

function zoomInAnimation(sectionElement: HTMLElement | null, element: HTMLElement | null) {
  const windowHeight = document.children[0].clientHeight;
  const off = windowHeight * 0.2;
  const positionY = sectionElement?.getBoundingClientRect().y || 0;
  const scale = (windowHeight - positionY + off) / 8 / 100;
  const initScale = 0.3;
  if (!!element && positionY >= off && scale >= initScale && scale < 1) {
    element.style.webkitTransform = `scale(${scale})`;
    element.style.transform = `scale(${scale})`;
  } else if (!!element && scale >= 1) {
    element.style.webkitTransform = 'scale(1)';
    element.style.transform = 'scale(1)';
  }
}

export default {
  fadeInUpAnimation,
  zoomInAnimation,
};
