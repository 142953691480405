import { useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { addDays } from 'date-fns';

import { useLazyQuery } from '@apollo/client';

import * as plansSelectors from '@/redux/plans/selectors';
import animations from '../utils/animations';
import { COOKIE_EXPIRATION_DAYS } from '../utils/constants';

import * as wizardActions from '../redux/wizard/actions';
import * as wizardSelectors from '../redux/wizard/selectors';

import getBrokerByCode from '../graphQL/queries/getBrokerByCode';
import getMailingLead from '../graphQL/queries/getMailingLead';

import {
  AvailablePlans as AvailablePlansType,
  AvailablePlan as AvailablePlanType,
} from '../types/availablePlans';
import { getCyberByFlags, IntervalCyber } from '@/utils/format';
import getExecutiveByCode from '@/graphQL/queries/getExecutiveByCode';
import { getFeatureFlags } from '@/redux/featureFlags/selectors';

export const useScroll = (position = 'start') => {
  const htmlElRef: any = useRef(null);
  const executeScroll = () =>
    htmlElRef.current.scrollIntoView({ behavior: 'smooth', block: position });

  return [executeScroll, htmlElRef];
};

export const useInterval = (callback: any, delay: number) => {
  const savedCallback: any = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};

export const usePrevious = (value: any) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useScrollAnimation = (
  ref: any,
  type: 'id' | 'class',
  name: string,
  offPercentage?: number
) => {
  useEffect(() => {
    const animation = () => {
      const section = ref.current;
      const elements =
        // eslint-disable-next-line
        type === 'id'
          ? (document.getElementById(name) as HTMLElement)
          : type === 'class'
          ? section?.getElementsByClassName(name)
          : null;

      !!elements && !!section && animations.fadeInUpAnimation(section, elements, offPercentage);
    };

    window.addEventListener('scroll', animation);
    animation();

    return () => {
      window.removeEventListener('scroll', animation);
    };
  }, []);
};

export const useBranding = (brokerCode?: string) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const router = useRouter();

  const [getBrokerByCodeFunc, { loading, data }] = useLazyQuery(getBrokerByCode, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',

    onCompleted: (response: any) => {
      if (!!response.getBrokerByCode) {
        const broker: any = response.getBrokerByCode;
        if (!broker) {
          cookies.remove('codeBrand');

          return;
        }
        const scheme = {
          code: broker.code,
          primary: broker.primary,
          character: broker.character
            ? `${process.env.urlBranding}/${broker.code}/${broker.character}`
            : null,
          logo: broker.logo ? `${process.env.urlBranding}/${broker.code}/${broker.logo}` : null,
          promo: broker?.promoBanner || false,
        };
        dispatch(wizardActions.setBranding(scheme));
        dispatch(wizardActions.setBusiness(broker.code));
      } else {
        dispatch(wizardActions.resetBranding());
        dispatch(wizardActions.resetBusiness());
        cookies.remove('codeBrand');
      }
    },

    onError: () => {
      dispatch(wizardActions.resetBranding());
      dispatch(wizardActions.resetBusiness());
      cookies.remove('codeBrand');
    },
  });

  useEffect(() => {
    let query = brokerCode ?? router.query.negocio;
    const { asistencia } = router.query;
    if (!query) {
      const codeBrandingCookie: any = cookies.get('codeBrand');
      codeBrandingCookie && (query = codeBrandingCookie);
      !codeBrandingCookie &&
        dispatch(wizardActions.resetBranding()) &&
        dispatch(wizardActions.resetBusiness());
    }
    if (query) {
      cookies.set('codeBrand', query, {
        expires: addDays(new Date(), COOKIE_EXPIRATION_DAYS),
      });
      if (asistencia) {
        cookies.set('assisted', asistencia);
      }
      getBrokerByCodeFunc({
        variables: {
          code: cookies.get('codeBrand'),
        },
      });
    }
  }, [brokerCode, router.query]);

  return {
    data: data?.getBrokerByCode,
    loading,
    code: brokerCode ?? router.query.negocio ?? cookies.get('codeBrand'),
  };
};

export const useExecutive = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const executiveCode = router.query.ejecutivo;
  const brokerCode = router.query.negocio;

  const [getExecutiveByCodeFunc, { loading, data }] = useLazyQuery(getExecutiveByCode, {
    onCompleted: (response: any) => {
      if (!!response.getExecutiveByCode) {
        const executive = response.getExecutiveByCode;
        if (executive.business === brokerCode) {
          dispatch(
            wizardActions.setExecutive({
              email: executive.email,
              name: executive.name,
            })
          );
        } else {
          dispatch(wizardActions.resetExecutive());
        }
      }
    },

    onError: () => {
      dispatch(wizardActions.resetExecutive());
    },
  });

  useEffect(() => {
    if (executiveCode && brokerCode) {
      getExecutiveByCodeFunc({
        variables: {
          code: executiveCode,
        },
      });
    }
  }, [router.query.ejecutivo]);

  return {
    executiveEmail: data?.getExecutiveByCode,
    loading,
  };
};

export const usePreloadMailingLead = () => {
  const availablePlans: AvailablePlansType = useSelector(plansSelectors.getAvailablePlans);

  const dispatch = useDispatch();
  const router = useRouter();

  const phoneFormatter = (phone: string) => {
    return `${phone.charAt(0)} ${phone.slice(1)}`;
  };

  const [getMailingLeadFunc, { loading, data }] = useLazyQuery(getMailingLead, {
    fetchPolicy: 'cache-first',

    onCompleted: (response: any) => {
      if (!!response.getMailingLead) {
        const mailingLead: any = response.getMailingLead;

        const actualPlan = availablePlans.find(
          (plan: AvailablePlanType) => plan._id === mailingLead.planId
        );
        actualPlan?.coverage.dental && dispatch(wizardActions.setDentalCoverage(true));
        actualPlan?.coverage.catastrophic && dispatch(wizardActions.setCatastrophicCoverage(true));

        dispatch(
          wizardActions.setUserData({
            companyRut: mailingLead.companyRut,
            companyName: mailingLead.companyName,
            name: mailingLead.name,
            lastname: mailingLead.lastname,
            position: mailingLead.position,
            email: mailingLead.email,
            phone: phoneFormatter(mailingLead.phone),
          })
        );

        dispatch(wizardActions.setWorkers(mailingLead.workers));
        dispatch(wizardActions.setPlanSelected(mailingLead.planId));
      }
    },

    onError: () => {},
  });

  useEffect(() => {
    const query: any = router.query.lead || '';

    if (query) {
      getMailingLeadFunc({
        variables: {
          id: query,
        },
      });
    }
  }, []);

  return { data: data?.getMailingLead, loading };
};

export const useCyberDates = (date: Date) => {
  const { promo: isPromo, code } = useSelector(wizardSelectors.getBranding);
  const flags = useSelector(getFeatureFlags);
  const cyberDay = getCyberByFlags(flags);
  const isCyberDayToday = !code && cyberDay !== null;

  const isCyberDay = useMemo(
    () => [IntervalCyber.CyberDay, IntervalCyber.CyberDayLastDay].includes(cyberDay ?? 0),
    [cyberDay]
  );

  const isPromoLastDay = useMemo(
    () => [IntervalCyber.CyberDayLastDay, IntervalCyber.ExtensionLastDay].includes(cyberDay ?? 0),
    [cyberDay]
  );

  return {
    isPromo,
    code,
    cyberDay: code ? null : cyberDay,
    isCyberDayToday,
    isCyberDay,
    isPromoLastDay,
  };
};

export const useIsAssisted = () => {
  return useMemo(() => {
    const cookies = new Cookies();
    const assisted = cookies.get('assisted');

    return assisted === '1';
  }, []);
};
