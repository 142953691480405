import { gql } from '@apollo/client';

import brokerFragment from '../fragments/brokerFragment';

export default gql`
  query getBrokerByCode($code: String!) {
    getBrokerByCode(code: $code) {
      ...brokerFragment
    }
  }
  ${brokerFragment}
`;
