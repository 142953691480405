import { gql } from '@apollo/client';

import executiveFragment from '../fragments/executiveFragment';

export default gql`
  query getExecutiveByCode($code: String!) {
    getExecutiveByCode(code: $code) {
      ...executiveFragment
    }
  }
  ${executiveFragment}
`;
