import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './Container.module.scss';

Col.defaultProps = {
  id: '',
  className: '',
};

export function Col({ id, className, sm, md, lg, xl, size, children }: ColPropsT) {
  const customClasses: Array<string> = [styles.col];
  !!className && customClasses.push(className);
  !!size && customClasses.push(styles[`col-${size}`]);
  !!sm && customClasses.push(styles[`col-sm-${sm}`]);
  !!md && customClasses.push(styles[`col-md-${md}`]);
  !!lg && customClasses.push(styles[`col-lg-${lg}`]);
  !!xl && customClasses.push(styles[`col-xl-${xl}`]);

  return (
    <div id={id} className={customClasses.join(' ')}>
      {children}
    </div>
  );
}

type ColPropsT = {
  id?: string;
  className?: string;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  size?: number;
  children: ReactNode;
};

Row.defaultProps = {
  className: '',
  justify: 'start',
  align: 'normal',
};

export function Row({ className, justify, align, cols, children }: RowPropT) {
  const customClasses = classnames(styles.row, {
    [`${className}`]: className,
    [styles[`row-${justify}`]]: justify,
    [styles[`row-align-${align}`]]: align,
    [styles[`row-cols-${cols}`]]: cols,
  });

  return <div className={customClasses}>{children}</div>;
}

type RowPropT = {
  className?: string;
  justify?: 'center' | 'start' | 'end' | 'between' | 'around';
  align?: 'center' | 'normal' | 'start' | 'end';
  cols?: number;
  children: ReactNode;
};

Container.defaultProps = {
  className: '',
  fluid: false,
  fullHeight: false,
};

type ContainerType = {
  className?: string;
  id?: string;
  fluid?: boolean;
  fullHeight?: boolean;
  children?: ReactNode;
};

export function Container({ className, fluid, fullHeight, children, id }: ContainerType) {
  const customClasses = classnames(styles[`${!!fluid ? 'container-fluid' : 'container'}`], {
    [`${className}`]: !!className,
    [styles.fullHeight]: !!fullHeight,
  });

  return (
    <div id={id} className={customClasses}>
      {children}
    </div>
  );
}

export default { Container, Row, Col };
