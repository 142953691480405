import React from 'react';
import PropTypes from 'prop-types';

import Image from 'next/image';
import styles from './SvgImg.module.scss';

import { SVG_LIST } from '@/utils/assets';

function SvgImg({ id, alt, name, size, customClass, onClick }: SvgImgT) {
  const customClasses =
    !!customClass && typeof customClass !== 'string' ? customClass.join(' ') : customClass;
  const iconClass: Array<string> = [];

  !!size && iconClass.push(styles[`svg_${size}`]);
  !!customClasses && iconClass.push(customClasses);
  !!onClick && iconClass.push(styles.svg_click);

  const Img = SVG_LIST[name as keyof typeof SVG_LIST];

  return (
    <Image
      id={id}
      src={Img}
      width={90}
      height={90}
      onClick={onClick || undefined}
      className={iconClass.join(' ')}
      alt={alt ?? 'Iconos'}
    />
  );
}

type SvgImgT = {
  id: string;
  size?: string;
  name?: keyof typeof SVG_LIST;
  customClass?: string | string[];
  onClick?: React.EventHandler<React.MouseEvent>;
  alt?: string;
};

SvgImg.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  name: PropTypes.string,
  customClass: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onClick: PropTypes.func,
  alt: PropTypes.string,
};

SvgImg.defaultProps = {
  id: '',
  customClass: '',
};

export default SvgImg;
