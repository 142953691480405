import { AvailablePlan as AvailablePlanType } from '@/src/types/availablePlans';
import { plansId } from '@/utils/constants';
import { StoreType } from '../store/index';
import { getBasePlans } from '@/utils/estimationFunctions';

export const getAvailablePlans = ({ plans }: StoreType) => {
  return plans.availablePlans.sort((planA: any, planB: any) =>
    planA.price.uf > planB.price.uf ? 1 : -1
  );
};

export const getPlanById =
  (id: string) =>
  ({ plans }: StoreType) =>
    plans.availablePlans.find(({ _id }: AvailablePlanType) => _id === id);

export const getPlansByType =
  () =>
  ({ plans, wizard }: StoreType) => {
    const { prePlanType } = wizard;

    return plans.availablePlans.filter(({ type }: AvailablePlanType) => type === prePlanType);
  };

export const getPreSelectedPlan =
  () =>
  ({ plans, wizard }: StoreType) => {
    const { prePlanSelected, planSelected } = wizard;

    if (!!prePlanSelected) {
      return plans.availablePlans.find(({ _id }: AvailablePlanType) => _id === prePlanSelected);
    }

    if (!!planSelected) {
      const selectedPlan = plans.availablePlans.find(
        ({ _id }: AvailablePlanType) => _id === planSelected
      );
      const filteredPlansByType = plans.availablePlans.filter(
        ({ type }: AvailablePlanType) => type === selectedPlan?.type
      );

      return filteredPlansByType.find(
        (plan: AvailablePlanType) =>
          plan.coverage.dental === null && plan.coverage.catastrophic === null
      );
    }
  };

export const getSelectedPlan =
  () =>
  ({ plans, wizard }: StoreType) => {
    const { planSelected } = wizard;

    return plans.availablePlans.find(({ _id }: AvailablePlanType) => _id === planSelected);
  };

export const getDentalPlan =
  () =>
  ({ plans, wizard }: StoreType) => {
    const { prePlanType, prePlanSelected, quantity } = wizard;
    const workers = quantity;

    if (!prePlanSelected) {
      return null;
    }

    const prePlan = plans.availablePlans.find(
      ({ _id }: AvailablePlanType) => _id === prePlanSelected
    );

    const prePlanCost = prePlan?.price?.uf || 0;

    const filteredPlansByType = plans.availablePlans.filter(
      ({ type }: AvailablePlanType) => type === prePlanType
    );

    const dentalPlan: AvailablePlanType = filteredPlansByType.find(
      (plan: AvailablePlanType) =>
        plan.coverage.dental !== null && plan.coverage.catastrophic === null
    );

    const dentalCost = dentalPlan?.price?.uf || 0;
    const cost = Math.abs(dentalCost - prePlanCost);
    const totalCost = cost * Number(workers);

    return {
      plan: dentalPlan,
      cost,
      totalCost,
      coverage: {
        dental: dentalPlan?.coverage.dental,
      },
    };
  };

export const getDentalPlanSigned =
  (workers: number) =>
  ({ plans, wizard }: StoreType) => {
    const { prePlanType, prePlanSelected } = wizard;

    if (!prePlanSelected) {
      return null;
    }

    const prePlan = plans.availablePlans.find(
      ({ _id }: AvailablePlanType) => _id === prePlanSelected
    );

    const prePlanCost = prePlan?.price?.uf || 0;

    const filteredPlansByType = plans.availablePlans.filter(
      ({ type }: AvailablePlanType) => type === prePlanType
    );

    const dentalPlan: AvailablePlanType = filteredPlansByType.find(
      (plan: AvailablePlanType) =>
        plan.coverage.dental !== null && plan.coverage.catastrophic === null
    );

    const dentalCost = dentalPlan?.price?.uf || 0;
    const cost = Math.abs(dentalCost - prePlanCost);
    const totalCost = cost * Number(workers);

    return {
      plan: dentalPlan,
      cost,
      totalCost,
      coverage: {
        dental: dentalPlan?.coverage.dental,
      },
    };
  };

export const getDentalAndCatastrophicPlan =
  () =>
  ({ plans }: StoreType) => {
    const filterDentalPlan = plans.availablePlans.find(
      (item: AvailablePlanType) => item._id === plansId.standardDental
    );
    const filterStandardPlan = plans.availablePlans.find(
      (item: AvailablePlanType) => item._id === plansId.standard
    );

    const filterCatastrophicPlan = plans.availablePlans.find(
      (item: AvailablePlanType) => item._id === plansId.standardCatastrophic
    );

    return {
      dental: filterDentalPlan?.price?.uf - filterStandardPlan?.price?.uf,
      catastrophic: filterCatastrophicPlan?.price?.uf - filterStandardPlan?.price?.uf,
    };
  };

export const getDentalCat =
  () =>
  ({ plans }: StoreType) => {
    const [getEconomicBasePlan] = getBasePlans({
      plans: plans.availablePlans,
      filterByHide: true,
      ascending: true,
    });

    const filterDentalPlan = plans.availablePlans.find(
      (plan: AvailablePlanType) =>
        plan.coverage.dental !== null &&
        getEconomicBasePlan.base === plan.type &&
        plan.coverage.catastrophic === null
    );

    const filterCatPlan = plans.availablePlans.find(
      (plan: AvailablePlanType) =>
        plan.coverage.dental === null &&
        getEconomicBasePlan.base === plan.type &&
        plan.coverage.catastrophic !== null
    );

    return {
      dental: filterDentalPlan?.price?.uf - getEconomicBasePlan?.price?.uf,
      catastrophic: filterCatPlan?.price?.uf - getEconomicBasePlan?.price?.uf,
    };
  };

export const getCatastrophicPlan =
  () =>
  ({ plans, wizard }: StoreType) => {
    const { prePlanType, prePlanSelected, quantity } = wizard;
    const workers = quantity;

    if (!prePlanSelected) {
      return null;
    }

    const prePlan = plans.availablePlans.find(
      ({ _id }: AvailablePlanType) => _id === prePlanSelected
    );
    const prePlanCost = prePlan?.price?.uf || 0;

    const filteredPlansByType = plans.availablePlans.filter(
      ({ type }: AvailablePlanType) => type === prePlanType
    );

    const catastrophicPlan: AvailablePlanType = filteredPlansByType.find(
      (plan: AvailablePlanType) =>
        plan.coverage.catastrophic !== null && plan.coverage.dental === null
    );

    const catastrophicCost = catastrophicPlan?.price?.uf || 0;
    const cost = Math.abs(catastrophicCost - prePlanCost);
    const totalCost = cost * Number(workers);

    return {
      plan: catastrophicPlan,
      cost,
      totalCost,
      coverage: {
        catastrophic: catastrophicPlan?.coverage.catastrophic,
      },
    };
  };

export const getVariantById =
  (id: string) =>
  ({ plans, wizard }: StoreType) => {
    const { planSelected } = wizard;

    if (!id) {
      return null;
    }

    const plan = plans.availablePlans.find(({ _id }: AvailablePlanType) => _id === planSelected);

    if (!plan) {
      return null;
    }

    return plan.variants.find((variant: any) => variant._id === id);
  };

export const getVariantsByPlanId =
  (id: string) =>
  ({ plans }: StoreType) => {
    if (!id) {
      return null;
    }

    const plan = plans.availablePlans.find(({ _id }: AvailablePlanType) => _id === id);

    if (!plan) {
      return null;
    }

    return plan.variants;
  };

export const getCoveragesByPlanId =
  (id: string) =>
  ({ plans }: StoreType) => {
    const plan = plans.availablePlans.find(({ _id }: AvailablePlanType) => _id === id);

    return Object.keys(plan.coverage)
      .map((key: string) => plan.coverage[key])
      .filter((coverage) => !!coverage);
  };

export const getBasePlanIdByType =
  (planType: string) =>
  ({ plans }: StoreType) => {
    const filteredPlansByType = plans.availablePlans.filter(
      ({ type }: AvailablePlanType) => type === planType
    );
    const basePlan = filteredPlansByType.find(
      (plan: AvailablePlanType) =>
        plan.coverage.dental === null && plan.coverage.catastrophic === null
    );

    return basePlan?._id || 0;
  };

export const getHasPromo = ({ plans }: StoreType) => {
  return plans.availablePlans.some((plan: AvailablePlanType) => plan.promo?.show);
};
